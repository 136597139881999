import React from "react"

import { StaticImage } from "gatsby-plugin-image"

import { graphql, useStaticQuery } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import { getImage } from "gatsby-plugin-image"
import { convertToBgImage } from "gbimage-bridge"
import Modal from "../modal/quickbase-modal"

import "./hero.css"

const HeroQuickbaseSupport = () => {
  const { heroImage } = useStaticQuery(
    graphql`
      {
        heroImage: file(relativePath: { eq: "new-hero-Background.png" }) {
          childImageSharp {
            gatsbyImageData(
              width: 1920
              layout: CONSTRAINED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
      }
    `
  )

  const heroImageData = getImage(heroImage)

  // Use like this:
  const bgImage = convertToBgImage(heroImageData)

  return (
    <>
      <div
        // Tag="div"
        // {...bgImage}
        // fluid={imageData}
        className="hero-banner hero-whitefade hero-home-quickbase-support"
        // preserveStackingContext
        style={{ backgroundPosition: "top center", height: "600px" }}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-6">
              <div className="hero-custom-quickbase">
                <h3 className="page-title font-weight-medium mt-0 mb-4">
                  Struggling to Get More Out of Quickbase?
                </h3>
                <p className="mb-0">
                  As your Quickbase Elite Partner, we'll rapidly automate your
                  business without the fuss. Streamline invoice processing,
                  inventory tracking, employee onboarding, and more.
                </p>
                <p className="h5 text-left pt-3 font-italic font-weight-bold mb-4">
                  Learn how we can help you
                </p>

                <Modal />
              </div>
            </div>
            <div
              className="col-lg-6 col-md-6 p-5"
              style={{ position: "relative" }}
            >
              <div
                className="quick-base-badge"
                style={{ position: "absolute", bottom: "25%", right: "0%" }}
              >
                <StaticImage
                  placeholder="dominantColor"
                  className="img-fluid rounded ml-auto"
                  src="../../images/new-images/qb-logo-2022.png"
                  alt="Solution Provider"
                  layout="constrained"
                  width={150}
                  height={150}
                  loading="lazy"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default HeroQuickbaseSupport
